/* General Section Styling */
.slogan-section {
    position: relative;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    color: white;
    padding: 80px 20px;
    z-index: 1;
}

.slogan-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Black overlay with 50% opacity */
    z-index: -1;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    position: relative; /* Ensures content stays above the overlay */
}

/* Content and layout */
.content-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
}

.text-section {
    text-align: left;
    max-width: 50%;
}

.text-section h2 {
    font-size: 40px;
    font-weight: bold;
}

.features-list {
    margin-top: 20px;
    font-size: 18px;
}

.features-list li {
    margin-bottom: 10px;
    list-style-type: none;
}

.features-list li::before {
    content: '✓';
    margin-right: 10px;
    color: #ff4c4c;
    font-weight: bold;
}

/* Steps Section */
.steps-section {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    width: 45%;
}

.step-box {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.icon-box {
    font-size: 50px;
    margin-bottom: 10px;
}

.step-title {
    font-size: 24px;
    font-weight: bold;
    color: #4d23e8; /* Primary color */
}

.step-text {
    font-size: 16px;
    color: #ccc;
    margin: 10px 0;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .content-wrapper {
        flex-direction: column;
        align-items: center;
    }

    .text-section, .steps-section {
        max-width: 100%;
        text-align: center;
    }

    .steps-section {
        grid-template-columns: 1fr;
    }
}
