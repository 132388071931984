/* General Section Styling */
.training-section {
    padding: 40px 20px;
    background-color: #f9f9f9;
}

.section-title {
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    color: #4d23e8; /* Updated color */
    margin-bottom: 40px;
}

/* Grid Layout for Trainings */
.training-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    justify-items: center;
}
