/* General Card Styling */
.training-card {
    position: relative;
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
}

.training-card:hover {
    transform: scale(1.05);
}

.training-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.training-content {
    padding: 20px;
    text-align: center;
}

.icon-wrapper {
    position: absolute;
    top: 15px;
    left: 15px;
    background-color: #4d23e8; /* Updated color */
    padding: 10px;
    border-radius: 50%;
    color: white;
}

.training-icon {
    font-size: 24px;
}

.training-title {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin-top: 20px;
}

.arrow-wrapper {
    position: absolute;
    bottom: 15px;
    right: 15px;
}

.arrow-link {
    color: #4d23e8; /* Updated color */
    font-size: 24px;
    text-decoration: none;
}

.arrow-link:hover {
    color: #3b1bb3; /* Darker shade on hover */
}
