/* Job Section Styling */
.job-section {
    padding: 40px 0;
}

.job-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
}

.btn {
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
}

/* Job Card Styling */
.job-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
}

.job-card:hover {
    transform: scale(1.05);
}

.job-image-wrapper {
    height: 180px;
    overflow: hidden;
}

.job-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.job-content {
    padding: 15px;
    text-align: center;
}

.job-title {
    font-size: 18px;
    font-weight: bold;
    color: #4d23e8;
    margin-bottom: 10px;
}

.job-company, .job-address, .job-salary, .job-contract {
    font-size: 14px;
    color: #666;
    margin-bottom: 5px;
}

.job-details-link a {
    color: #4d23e8;
    text-decoration: none;
    font-weight: bold;
    margin-top: 10px;
    display: inline-block;
}

.job-details-link a:hover {
    text-decoration: underline;
}
