/* General Section Styling */
.testimonials-section {
    background-color: #f1f5f9;
    padding: 60px 20px;
    text-align: center;
}

.section-title {
    font-size: 36px;
    font-weight: bold;
    color: #4d23e8;
    margin-bottom: 10px;
}

.section-subtitle {
    font-size: 24px;
    color: #ff4c4c; /* Red text for emphasis, as in your reference */
    margin-bottom: 40px;
}

/* Testimonial Slider */
.testimonial-slider {
    display: flex;
    gap: 30px;
    overflow-x: auto;
    padding: 10px;
    scroll-snap-type: x mandatory;
    scrollbar-width: none; /* Hides the scrollbar for modern browsers */
}

.testimonial-slider::-webkit-scrollbar {
    display: none; /* Hides the scrollbar for Chrome, Safari, and Opera */
}

.testimonial-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    flex: 0 0 300px;
    scroll-snap-align: start;
    transition: transform 0.3s ease;
}

.testimonial-card:hover {
    transform: scale(1.05);
}

.testimonial-quote {
    font-size: 18px;
    font-style: italic;
    color: #333;
    margin-bottom: 20px;
}

.testimonial-info {
    display: flex;
    align-items: center;
    gap: 15px;
}

.testimonial-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.testimonial-name {
    font-weight: bold;
    font-size: 16px;
    color: #4d23e8;
}

.testimonial-benefit {
    font-size: 14px;
    color: #666;
}

/* Responsive Design */
@media (max-width: 768px) {
    .testimonial-slider {
        flex-direction: column;
        gap: 20px;
    }
}
