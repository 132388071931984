/* General Section Styling */
.slogan-section {
    position: relative;  /* Ensure relative positioning for the overlay */
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    color: white;
    padding: 80px 20px;
    z-index: 1;
}

.slogan-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Black overlay with 50% opacity */
    z-index: -1;  /* Send it behind the content */
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    position: relative; /* Ensures content stays above the overlay */
}

/* Content and layout */
.content-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
}

.text-section {
    text-align: left;
    max-width: 50%;
}

.text-section h2 {
    font-size: 40px;
    font-weight: bold;
}

.features-list {
    margin-top: 20px;
    font-size: 18px;
}

.features-list li {
    margin-bottom: 10px;
    list-style-type: none;
}

.features-list li::before {
    content: '✓';
    margin-right: 10px;
    color: #ff4c4c;
    font-weight: bold;
}

/* Stats Section */
.stats-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    width: 45%;
}

.stat-box {
    background-color: rgba(255, 255, 255, 0.1); /* Transparent background for stats */
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.icon-box {
    font-size: 50px;
    margin-bottom: 10px;
}

.stat-number {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 10px;
}

.stat-text {
    color:rgb(183, 216, 253);
    font-size: 18px;
    margin-top: 10px;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .content-wrapper {
        flex-direction: column;
        align-items: center;
    }

    .text-section, .stats-section {
        max-width: 100%;
        text-align: center;
    }

    .stats-section {
        grid-template-columns: 1fr;
    }
}
