/* Job Card Styling */
.job-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
    position: relative;
}

.job-card:hover {
    transform: scale(1.05);
}

.job-image-wrapper {
    height: 180px;
    overflow: hidden;
}

.job-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.job-content {
    padding: 15px;
    text-align: center;
}

.job-title {
    font-size: 18px;
    font-weight: bold;
    color: #4d23e8;
    margin-bottom: 10px;
}

.job-company {
    font-size: 14px;
    color: #666;
    margin-bottom: 5px;
}

.job-address {
    font-size: 14px;
    color: #999;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.job-address i {
    margin-right: 5px;
    color: #4d23e8; /* Icon color */
}

.job-salary {
    font-size: 16px;
    color: #333;
    margin-bottom: 10px;
}

.job-contract {
    font-size: 14px;
    color: #4d23e8;
    font-weight: bold;
    margin-bottom: 10px;
}

.job-details-link a {
    color: #4d23e8;
    text-decoration: none;
    font-weight: bold;
    margin-top: 10px;
    display: inline-block;
    transition: color 0.3s;
}

.job-details-link a:hover {
    text-decoration: underline;
    color: #3b1bb3; /* Darker shade on hover */
}

.badge {
    background-color: #4d23e8;
    color: white;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: bold;
}

/* Responsive Design */
@media (max-width: 768px) {
    .job-card {
        margin-bottom: 20px;
    }

    .job-image-wrapper {
        height: 150px;
    }
}
